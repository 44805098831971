import React, { useState, useRef, useEffect } from "react"
import { connect, useSelector } from "react-redux"
import tw, { styled, css } from "twin.macro"
import Heading from "general/components/Heading"
import LoginForm from "form/components/LoginForm"
import CustomTokenForm from "form/components/CustomTokenForm"
import { getFirebase } from "react-redux-firebase"

import AdminFrame from "auth/components/AdminFrame"
import TextField from "general/components/TextField"
import Button from "general/components/Button"
import SkeletonDisplayText from "general/components/SkeletonDisplayText"
import { navigate } from "gatsby"

const Header = styled.header`
  ${tw`block shadow-sm fixed top-0 left-0 right-0`}
  flex-shrink: 0;
`

const Wrapper = styled.div`
  ${tw`flex flex-col w-full h-full`}
`
function LoginPage(props) {
  const firebase = getFirebase()
  const [idToken, setIdToken] = useState("")
  const [search, setSearch] = useState("")
  const [changed, setChanged] = useState(false)
  const uid = useRef()

  const handleSubmit = () => {
    if (!(uid && uid.current && uid.current.value)) {
      return
    }

    const suffixToDomain = ".myshopify.com"
    const shopUrl = uid.current.value.includes(suffixToDomain)
      ? uid.current.value
      : uid.current.value + suffixToDomain
    setSearch(changed ? "" : shopUrl)
    setChanged(c => !c)
  }

  useEffect(() => {
    if (!process.env.GATSBY_DEMO_MODE) {
      return navigate("/login")
    }

    const onKeyPress = e => {
      if (e.key === "Enter") {
        handleSubmit()
      }
    }
    window.addEventListener("keypress", onKeyPress)

    firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        return navigate("/admin/login")
      }
      const token = await user.getIdToken(true)

      if (token) {
        setIdToken(token)
      }
    })
    // firebase
    //   .auth()
    //   .currentUser.getIdToken()
    //   .then(token => (token ? setIdToken(token) : null))
    //   .catch(console.log)
    return () => window.removeEventListener("keypress", onKeyPress)
  }, [])

  return (
    <Wrapper>
      <Header>
        {/* <div css={tw`text-center text-primary-600`}>Admin Top bar</div> */}
        <TextField
          type="text"
          name="uid"
          placeholder={"Shop url"}
          ref={uid}
          connectedRight={
            <Button
              primary
              children={changed ? "disconnect" : "connect"}
              onClick={handleSubmit}
            />
          }
        />
      </Header>
      {changed && search ? (
        <AdminFrame uid={search} idToken={idToken} />
      ) : (
        <div css={tw`h-32 p-2 flex justify-between pt-12`}>
          <span>↑ 1. search by shop name ↑</span>
          <span>↑ 2. click me ↑</span>
        </div>
      )}
    </Wrapper>
  )
}

export default connect(undefined, {})(LoginPage)
