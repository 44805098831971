import React from "react"
import tw from "twin.macro"

function AdminFrame(props) {
  const { idToken, uid } = props

  return (
    <iframe
      css={tw`flex flex-col justify-center items-center min-h-screen pt-12`}
      src={`/admin/login?token=${idToken}&uid=${uid}`}
      width={"100%"}
      height={"100%"}
    />
  )
}

export default AdminFrame
